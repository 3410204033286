import React from 'react';
import Login from './Login';
import Account from './Account';

class Header extends React.Component {

  render() {
    return (
      <div className="nav">
        <div className="nav-logos">
          <img src="/berlin-logo.png" alt="Berlin logo" />
          <a href="https://anlaufstelle-bürgerbeteiligung.de/"><img src="/anlaufstelle-tk-arrow.svg" alt="Anlaufstelle TK logo" /></a>
        </div>

        {/*<img className="infotool-svg" src="/infotool.svg" alt="Tool tip"/>*/}
        <Login setLogin={this.props.setLogin} authenticated={this.props.authenticated} setMessage={this.props.setMessage}/>
        <Account authenticated={this.props.authenticated} setLogout={this.props.setLogout}
          showManageAccount={this.props.showManageAccount} showDialog={this.props.showDialog} />
      </div>
    );
  }
}

export default Header
