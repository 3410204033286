import React from 'react'

class Footer extends React.Component {

  render() {
    return (
      <footer className="site-footer">
        <div className="site-info">
          <a href="https://www.berlin.de/"><img className="site-info-element footer-logo" src="/berlin-logo.png" alt="Berlin Logo" />
          </a>
          <a href="https://www.berlin.de/ba-treptow-koepenick/politik-und-verwaltung/service-und-organisationseinheiten/sozialraumorientierte-planungskoordination/"><img className="site-info-element footer-logo" src="/spk-logo.jpg" alt="Berlin Logo" />
          </a>
          <a href="https://ag-urban.de"><img className="site-info-element footer-logo" src="/ag-urban-logo.png" alt="AG Urban Logo" />
          </a>

          <a href="https://www.berlin.de/ba-treptow-koepenick/"><img className="site-info-element footer-logo" src="/tk-bezirks-logo.png" alt="Berlin Logo" /></a>
          <a href="https://anlaufstelle-bürgerbeteiligung.de/"><img className="site-info-element footer-logo" src="/anlaufstelle-tk-arrow.svg" alt="Anlaufstelle" /></a>
          <h2 className="site-info-element"><a target="_blank" rel="noreferrer" href="https://ag-urban.de/impressum/">Impressum</a></h2>
        </div>
      </footer>
    );
  }
}

export default Footer
