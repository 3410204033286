import React, { Component } from "react";
import Switch from "react-switch";

// https://www.npmjs.com/package/react-switch


//https://www.digitalocean.com/community/tutorials/how-to-build-a-custom-toggle-switch-with-react

class ToggleSwitch extends Component {
  constructor() {
    super();
    this.state = {
      checked: false,
      height:20,
      handleDiameter:20
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {

    if (this.props.height !== undefined){
       this.setState({ height:this.props.height, handleDiameter:this.props.handleDiameter })

    }

    if (this.props.checked !== undefined){
       this.setState({ checked: this.props.checked })
    }
  }

  handleChange(checked) {
    
      if (this.props.checked !== undefined){
      this.setState({ checked });
      const { layerId } = this.props;
      this.props.onToggleLayer({layerId, checked});
    }
  }

  render() {
    return (
      <div className="toggleswitch">
        <Switch
          onChange={this.handleChange}
          checked={this.props.checked}
          onColor="#9185be"
          onHandleColor="#fff"
          handleDiameter={this.props.handleDiameter}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={this.state.height}
          width={35}
          className="react-switch"
          id="material-switch"
          disabled={this.props.disabled}
        />

        <h3> {this.props.name}</h3>

      </div>
    );
  }
}
export default ToggleSwitch
