import React from 'react'

class Tooltip extends React.Component {

  state = this.props.dataFromParent.tooltipInfo;

  onChange = (d,v) => {

   this.props.dataProviderCallback({tooltipInfo: {...this.state, visibility:"hidden",open:true}});

  }

  render() {


    const d = this.props.dataFromParent.tooltipInfo || {};

    return (
        <div className="tooltip-wrapper" style={{left: d.x-20, top: d.y-100,visibility:d.visible}} onMouseLeave={() => {this.onChange(d,"hidden")} } >
          <div className="tooltip">
                 
             {(d.visible === "visible") &&
                <div>
                  {(d.type === "projects") &&
                  <div>
                    <h2 className="tooltip-title"> {d.info.title} </h2>
                    <p>Letzter Tag: {d.info.active_phase.split("'")[3].split(" ")[0]}</p>
                    <p>Noch: {d.info.active_phase.split("'")[1]}</p>
                    <p><a href={"https://www.mein.berlin.de" + d.info.url} rel="noopener noreferrer" target="_blank">[link]</a></p>
                  </div>
                  }
                  {(d.type === "redev_areas") &&
                    <div>
                      <h2 className="sidebar-title">{d.info}</h2>
                    </div>
                  }
                  {(d.type === "poi") &&
                    <div>
                      <h2 className="sidebar-title">{d.info}</h2>
                    </div>
                  }
                </div>
              }
          </div>
        </div>
    );
  }
}

export default Tooltip
