import DataProvider from './components/DataProvider'

function App() {

  return (
      <DataProvider/>
  );

}

export default App;
