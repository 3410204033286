import React from 'react'

class Login extends React.Component {

  state = {
    username: '',
    password: '',
    passwordConfirm: '',
    showLogin: false,
    showRegistration: false,
    showAccount: false
  }

  toggleLogin = () => {
    this.setState(prevState => ({
      showLogin: !prevState.showLogin,
      showRegistration: false
    }));
  }

  toggleRegistration = () => {
    this.setState(prevState => ({
      showRegistration: !prevState.showRegistration,
      showLogin: false
    }));
  }

  toggleAccount = () => {
    this.setState(prevState => ({
      showAccount: !prevState.showAccount
    }));
  }

  handleLogin = (event) => {
    event.preventDefault(); // don't reload

    // remove animation for repeat errors
    const form = document.getElementById('login-form');
    form.classList.remove('login-error');

    // authenticate
    fetch('/login', {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email:    this.state.username,
        password: this.state.password
      })
    }).then(res => res.json())
      .then(res => {
        if (res.success) {
          // username and password match
          this.props.setMessage(res.message);
          this.props.setLogin();
          this.setState({password: '', username: ''});
        } else {
          // error loggin in
          this.props.setMessage(res.message);
          this.setState({password: ''});
          form.classList.add('login-error');
        }
      }
    );
  }

  handleRegistration = (event) => {
    event.preventDefault(); // don't reload

    // remove animation for repeat errors
    const form = document.getElementById('registration-form');
    form.classList.remove('login-error');

    if (this.state.password !== this.state.passwordConfirm) {
      this.props.setMessage("Das Bestätigungspasswort muss mit ihrer Eingabe übereinstimmen");
      this.setState({password: '', passwordConfirm: ''});
      form.classList.add('login-error');
      return;
    }

    // authenticate
    fetch('/register', {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email:    this.state.username,
        password: this.state.password
      })
    }).then(res => res.json())
      .then(res => {
        if (res.success) {
          // username and password match
          //this.props.setLogin(res.email);
          this.props.setMessage(res.message);
          this.setState({password: '', passwordConfirm: '', username: ''});
          this.toggleRegistration(); // hide registration window
        } else {
          console.log(res.message);
          // error loggin in
          this.props.setMessage(res.message);
          this.setState({password: '', passwordConfirm: ''});
          form.classList.add('login-error');
        }
      }
    );
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {

    return (
      <div className="login">
        { !this.props.authenticated &&
          <div className="login-container">
            <h2 onClick={() => this.toggleLogin() } className="account-item">Anmelden</h2>
            <h2 onClick={() => this.toggleRegistration() } className="account-item">Registrieren</h2>
            {this.state.showLogin &&
              <form id="login-form" className="user-form" onSubmit={this.handleLogin}>
                <input name="username" type="text" placeholder="Email" value={this.state.username} onChange={this.handleChange} required />
                <input name="password" type="password" placeholder="Passwort" value={this.state.password} onChange={this.handleChange} required />
                <button type="submit" value="Submit">Anmelden</button>
              </form>
            }
            {this.state.showRegistration &&
              <form id="registration-form" className="user-form" onSubmit={this.handleRegistration}>
                <input name="username" type="text" placeholder="Email" value={this.state.username} onChange={this.handleChange} required />
                <input name="password" type="password" placeholder="Passwort" value={this.state.password} onChange={this.handleChange} required />
                <input name="passwordConfirm" type="password" placeholder="Passwort wiederholen" value={this.state.passwordConfirm} onChange={this.handleChange} required />
                <button type="submit" value="Submit">Registrieren</button>
              </form>
            }
          </div>
        }
      </div>


    );
  }
}

export default Login
