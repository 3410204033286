import React from 'react'
import ToggleSwitch from './ToggleSwitch'

class Layers extends React.Component {
   constructor(props) {
    super(props);
    this.state = {
      layers: [
        {
          id: 'project-svg',
          name: "Vorhaben",
          checked: true
        },
        {
          id: 'poi-svg',
          name: "Meine Orte",
          checked: true
        },
        {
          id: 'dev-plan-svg',
          name: "Bebauungspläne",
          checked: false
        },
        {
          id: 'redev-area-svg',
          name: "Sanierungsgebiete",
          checked: false
        }
      ]
    };


  }
  componentDidMount() {
    this.props.dataProviderCallback({layerStatus:this.state.layers});
    // TODO: get this working
    if (!this.state.authenticate) {
      this.addPoiLayer();
    }
  }

  addPoiLayer = () => {
    let layers = [...this.state.layers];
    layers.push({
              id: 'poi-svg',
              name: "Meine Orte",
              checked: true
            });
    this.setState(layers);
  };


  onToggleLayerStatus = (toggledLayer) => {

    const layers = this.state.layers;
    const nextLayerState = layers.map(layer => {
      if (toggledLayer.layerId !== layer.id) return layer;
      else {
        return {
          ...layer,
          checked: toggledLayer.checked
        };
      }
    });

    // update layer states and map data
    this.setState(prevState => ({ layers: nextLayerState }), () => {
      this.props.dataProviderCallback({layerStatus:this.state.layers});
      }
    );

  }

  handleChange = (event) => {
      this.setState({value: event.target.value});
  }

  render() {

    const layers = this.state.layers;

    return (

      <div className="layers">
      {layers && layers.map(item => (
        <ToggleSwitch
            key={item.id}
            name={item.name}
            layerId={item.id}
            checked={item.checked}
            onToggleLayer={this.onToggleLayerStatus}
         />
        ))}
      </div>

    );
  }
}

export default Layers
