import React      from 'react';
import { BrowserRouter, Route, Redirect } from 'react-router-dom'
import Basemap    from './Basemap';
import Header from './Header';
import Sidebar    from './Sidebar';
import Message    from './Message';
import Dialog     from './Dialog';
import Footer     from './Footer';
import Tooltip    from './Tooltip';

// import raw_poi from '../data/poi.json';
// import raw_projects from '../data/projects.json';
// import raw_dev_plans from '../data/dev_plans.json';
// import raw_redev_areas from '../data/redev_areas.json';
import raw_treptow_kopenick from '../data/Treptow_Kopenick.json';


class DataProvider extends React.Component {

  constructor(props: Props) {

    super(props);

    this.state = {
      isAuthenticated: false, // TODO: move authentication out of state
      user_id: null,
      poi: {"item":"nodata"}, //["nodata"],
      newPoi: null,
      projects: null,
      dev_plans: null,
      redev_areas: null,
      isLoading: true,
      slidervalue: 0,
      currentPoi:{id:null},
      tooltipInfo: {
        name: 'name',
        x: 0,
        y: 0,
        visible: "hidden"
      },
      highlight: {
        "type": "Feature",
        "geometry": {"type": "point","coordinates": [0,0]}
      },
      visibility : "hidden",
      message: "",
      showMessage: false,
      showDialog: false,
      showManageAccount: false,
    }

  }

  authenticate = () => {
    this.isAuthenticated();
  }

  logout = () => {
    this.setState({
      isAuthenticated: false,
      user_id: null,
      poi: null
    })
    // check if token cookie is valid
    fetch('/logout', {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });
  }

  isAuthenticated = () => {
    // check if token cookie is valid
    fetch('/auth', {
      method: 'POST',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(res => {
        if (res.success) {
          this.setState({
            isAuthenticated: true,
            user_id: res.user_id
          })

          // load pois
          Promise.all([
            fetch('/poi?user_id=' + this.state.user_id).then(response => response.json())
            ]).then(allResponses => {
              if (allResponses[0][0]) {
                const poi = allResponses[0][0].jsonb_build_object
                this.setState({ poi });
              }
          })
          .catch(error => console.log(error))

        } else {
          this.setState({
            isAuthenticated: false,
            user_id: null,
            poi: null
          })
          console.log(res.message);
        }
      }
    );
  }

  confirmEmail = (props) => {
    const id = props.match.params.id;

    fetch(`/confirm/${id}`, {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(res => res.json())
      .then(res => {
        this.isAuthenticated();
      }
    );
  }

  componentDidMount() {

    /* start fetch data from database */
    Promise.all([
      fetch("/projects").then(response => response.json()),
      fetch("/dev_plans").then(response => response.json()),
      fetch("/redev_areas").then(response => response.json()),
      ]).then(allResponses => {

        const projects = allResponses[0][0].jsonb_build_object
        const dev_plans = allResponses[1][0].jsonb_build_object
        const redev_areas = allResponses[2][0].jsonb_build_object
        this.setState({
          projects,
          dev_plans,
          redev_areas,
          bezirk_boundary: raw_treptow_kopenick,
          isLoading: false
        });

      }).catch(error => console.log(error));
    /* end fetch data from database */

    // check if user is authenticated
    this.isAuthenticated();

  }


dialogCallback = (childData) => {

  this.setState({
    highlight: childData.highlight,
    highlightClass: childData.highlightClass,
    poi: childData.poi,
    slidervalue: childData.slidervalue,
    showDialog: childData.showDialog,
    showManageAccount: childData.showManageAccount,
    currentPoi:childData.currentPoi
  });

}

basmapCallback = (childData) => {

  this.setState({
    newCoords: childData.newCoords,
    cancel: childData.cancel,
    tooltipInfo: childData.tooltipInfo,
    sidebarInfo: childData.sidebarInfo
  });

}

layerCallback = (childData) => {

  this.setState({layerStatus:childData.layerStatus})

}

tooltipCallback = (childData) => {

  this.setState({tooltipInfo:childData})

}

sidebarCallback = (childData) => {
  //console.log(childData)

  this.setState({highlight:childData.highlight, highlightClass:childData.highlightClass})

}

setMessage = (msg) => {

  this.setState({
    message: msg,
    showMessage: true
  });

}

closeMessage = () => {

  this.setState({
    message: '',
    showMessage: false
  });

}

showManageAccount = () => {
  console.log("show manage account")
  this.setState({
    showManageAccount: true,
    showDialog: false
  });
}

showDialog = () => {
  this.setState({
    showDialog: true,
    showManageAccount: false
  });
}


render() {

    // only render if data has loaded
    if (this.state.isLoading) {
      // TODO: loading screen
      return null;
    }

    return (
      <div className="flex-fullscreen">

        <Header setLogin={this.authenticate} authenticated={this.state.isAuthenticated}
                setLogout={this.logout} setMessage={this.setMessage}
                showManageAccount={this.showManageAccount} showDialog={this.showDialog} />

        <div className="main-container">
          <Sidebar dataFromParent={this.state} dataProviderCallback={this.sidebarCallback}
                dataProviderCallbackLayer={this.layerCallback} />
          <Basemap dataFromParent={this.state} dataProviderCallback={this.basmapCallback} />
          <Dialog  dataFromParent={this.state} dataProviderCallback={this.dialogCallback} />
          <Tooltip dataFromParent={this.state} dataProviderCallback={this.tooltipCallback} />
        </div>

        <Footer />

        <Message message={this.state.message} showMessage={this.state.showMessage} closeMessage={this.closeMessage} />

        {/* handle confirmation link and reset all routes to / */}
        <BrowserRouter>
          <Route exact path="/confirm/:id" render={(props) => (this.confirmEmail(props))} />
          <Redirect from="*" to="/" />
        </BrowserRouter>

      </div>
    )
  }
}

export default DataProvider
