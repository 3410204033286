import React from 'react' ;
import RangeSlider from'./RangeSlider';
import * as turf from '@turf/helpers'

class Dialog extends React.Component {

  constructor(props: Props) {

    super(props);

   // this.clearForm = this.clearForm.bind(this);

    this.state = {
      isLoading: true,
      showDialog: false,
      showManageAccount: false,
      showPoiList: false,
      isEditing: false,
      currentPoi:{id:null},
      slidervalue: 0,
      startValue: 0,
      value:"none",
      poi: this.props.dataFromParent.poi,
      highlight: { "type": "Feature","geometry": {"type": "point","coordinates": [0,0]} },
                 visibility : "hidden"
    };

  }

  handleReset = (event) => {
    this.setState({value: ""});
    this.showDialog();
  }

/*  handleChange = (event) => {
    this.setState({value: event.target.value});
  }*/

  sendData = (data) => {
    // .log(data)
    this.props.dataProviderCallback(data);
  }

  onSlideStatus = (slidervalue) => {

    const rad = turf.lengthToDegrees(slidervalue.value*100,'meters');

    this.setState({ slidervalue: rad,percentRadius:slidervalue.value })
    this.sendData(this.state)
  }

  showDialog = () => {

    this.setState(prevState => ({
      showDialog: !prevState.showDialog,
      showManageAccount: false
    }));

    const showDialog = this.state.showDialog ? false : true;
    this.getPoi();
    this.sendData({
      isLoading: this.state.isLoading,
      showDialog: showDialog,
      showManageAccount: this.state.showManageAccount,
      slidervalue: this.state.slidervalue,
      poi: this.state.poi,
      highlight: this.state.highlight,
      currentPoi:this.state.currentPoi
    })
  }

  showManageAccount = () => {

    this.setState(prevState => ({
      showManageAccount: !prevState.showManageAccount,
      showDialog: false
    }));

    const showManageAccount = this.state.showManageAccount ? false : true;
    this.getPoi();
    this.sendData({
        isLoading: this.state.isLoading,
        showDialog: this.state.showDialog,
        showManageAccount: showManageAccount,
        slidervalue: this.state.slidervalue,
        poi: this.state.poi,
        highlight:this.state.highlight,
        currentPoi:this.state.currentPoi
    });

  }

   showPoiList = () => {

    this.setState(prevState => ({
      showPoiList: !prevState.showPoiList
    }));

  }

  deletePoi = (id, subject) => {

      fetch('/poi', {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: id
        })
      }).then(response => {
        this.getPoi();
      });

  }

  getPoi = () => {
    fetch('/poi?user_id=' + this.props.dataFromParent.user_id)
      .then(response => response.json())
      .then(data => {
         this.setState({poi:data[0].jsonb_build_object})
         this.sendData({
            isLoading: this.state.isLoading,
            showDialog: this.state.showDialog,
            showManageAccount: this.state.showManageAccount,
            slidervalue: this.state.slidervalue,
            poi: this.state.poi,
            currentPoi:this.state.currentPoi
          })

      })
      .catch(error => {
        console.log(error);
      })

  }

  savePoi = (event) => {

    event.preventDefault();
    if (this.props.dataFromParent.newCoords) {


      fetch('/add_poi', {
        method: 'POST',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          poi_name: event.target.poi_name.value,
          lat: this.props.dataFromParent.newCoords[0],
          lon: this.props.dataFromParent.newCoords[1],
          radius: this.state.slidervalue,
          user_id: this.props.dataFromParent.user_id
        })
      }).then(response => {

       this.getPoi();

      });


      this.showDialog()
      this.setState({slidervalue:0,value:""})

      }

  }

  updatePoi= (event) => {

    console.log()
    var newName = event.target.poi_name.value
    if (!newName){
      //console.log(this.state.currentPoi)
      newName = this.state.currentPoi.poi
    }

    event.preventDefault();
      fetch('/poi', {
       method: 'PUT',
        mode: 'cors',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          poi_name: newName,
          radius: this.state.slidervalue,
          id: this.state.currentPoi.id,
          user_id: this.props.dataFromParent.user_id
        })
      }).then(response => {

           this.getPoi();
           this.closeDialog();


      });

  }

  editPoi = (poi) => {

    this.setState(prevState => ({
      isEditing: !prevState.isEditing,
      currentPoi:poi,
      startValue:poi.radius*1111.1111,
      slidervalue:poi.radius
    }));

     this.sendData({
        isLoading: this.state.isLoading,
        showDialog: this.state.showDialog,
        showManageAccount: this.state.showManageAccount,
        slidervalue: poi.radius,
        poi: this.state.poi,
        showOrte: this.state.showOrte,
        highlight:this.state.highlight,
        currentPoi:poi
    });

  }

  closeDialog = (event) => {
    //console.log("UPSAt")

    this.setState({currentPoi:{id:null}})

      this.sendData({
        isLoading: this.state.isLoading,
        showDialog: this.state.showDialog,
        showManageAccount: this.state.showManageAccount,
        slidervalue: this.state.slidervalue,
        poi: this.state.poi,
        showOrte: this.state.showOrte,
        highlight:this.state.highlight,
        currentPoi:{id:null}
    });



  }

  clearForm = (event) => {

    event.preventDefault();
    this.setState({slidervalue:0,value:""})

  }

  toggleView = () => {

    this.setState(prevState => ({
      showOrte: !prevState.showOrte
    }));

    const showOrte = this.state.showOrte ? false : true;
    this.getPoi();
    this.sendData({
        isLoading: this.state.isLoading,
        showDialog: this.state.showDialog,
        showManageAccount: this.state.showManageAccount,
        slidervalue: this.state.slidervalue,
        poi: this.state.poi,
        showOrte: showOrte,
        highlight:this.state.highlight,
        currentPoi:this.state.currentPoi
    });

  }

  highlightMap = (d,v) => {
    this.setState({highlight:d})
     // console.log(this.state.poi)
     this.sendData({
        isLoading: this.state.isLoading,
        showDialog: this.state.showDialog,
        slidervalue: this.state.slidervalue,
        poi: this.state.poi,
        showOrte: this.state.showOrte,
        showManageAccount: this.state.showManageAccount,
        highlight:d,
        highlightClass:"highlight-poi",
        currentPoi:this.state.currentPoi
    });

 //  this.props.dataProviderCallback({highlight:d});

  }

   unHighlightMap = (d,v) => {


     // console.log(this.state.poi)
     const highlight = { "type": "Feature","geometry": {"type": "point","coordinates": [0,0]} ,
                 highlightClass : "highlight-poi"}

     this.setState({highlight:highlight})

     this.sendData({
        isLoading: this.state.isLoading,
        showDialog: this.state.showDialog,
        slidervalue: this.state.slidervalue,
        poi: this.state.poi,
        showOrte: this.state.showOrte,
        showManageAccount: this.state.showManageAccount,
        highlight:highlight,
        highlightClass:"highlight-poi",
        currentPoi:this.state.currentPoi
    });
  }

  render() {

    // only render component if logged in
     if (!this.props.dataFromParent.isAuthenticated) {
       return null;
     }

    var items = []

    //onsole.log(this.state)

    if (this.state.poi !== null) {
      items = this.state.poi.features || []; // if there's no items, give empty array
    }

   //console.log(this.state.currentPoi)

  return (

      <div className="pois-container">

        {/* Map icons */}
        <div className="add-poi-container">
          <img className="add-poi" src="/add.svg" onClick={() => this.showDialog() } />
          <img className="my-pois" onClick={() => this.showManageAccount() } src="/settings.svg" alt="Meine Orte verwalten" />
        </div>

        {/* Add poi container */}
        <div>
          { this.props.dataFromParent.showDialog &&
            <div>
              <form className="add-poi-dialog" onSubmit={this.savePoi}>
                <div className="right-icons">
                  <img className="poi-icon" src="/x.svg" onClick={this.handleReset} />
                </div>

                <input type="text" id="poi_name"  placeholder="Mein Ort #1" />
                <p>Radius:</p>
                <RangeSlider onSlide={this.onSlideStatus}/>
                <input type="submit" value="Speichern" />
              </form>
           </div>
          }
        </div>

        {/* Account management container */}
        <div>
        { this.props.dataFromParent.showManageAccount &&
          <div>
            <div className="pois">
              <h2 className="">Meine Orte verwalten</h2>
              {items.map(d =>
                <div className="poi" onMouseOver={() => {this.highlightMap(d,"visible")} } onMouseOut={() => {this.unHighlightMap(d,"hidden")}}>
                  <li key={d.properties.id}>{d.properties.poi}</li>
                  {d.properties.id === this.state.currentPoi.id &&
                    <div>
                      <img className="poi-icon" src="/x.svg" alt="Delete" onClick={() => this.closeDialog(d.properties)}/>
                      <form className="add-poi-dialog" onSubmit={this.updatePoi}>
                        <input type="text" id="poi_name"  placeholder="Neuer Ort" />
                        <p>Radius:</p>
                        <RangeSlider onSlide={this.onSlideStatus} startValue={this.state.startValue}/>
                        <input type="submit" value="Aktualisieren" />
                      </form>
                      <div className="delete-ort">
                        <p>Mein Ort löschen</p><img className="poi-icon" src="/trash.svg" alt="Delete" onClick={() => this.deletePoi(d.properties.id)} />
                      </div>
                    </div>
                  }
                  {d.properties.id !== this.state.currentPoi.id &&
                    <img className="poi-icon" src="/edit.svg" alt="Edit" onClick={() => this.editPoi(d.properties)} />
                  }
                </div>
              )}

              <h2 className="">Password ändern</h2>
              <h2 className="">Konto Löschen</h2>

            </div>
          </div>
        }
        </div>

      </div>

     )
  }
}

export default Dialog
