import React from 'react'

class Account extends React.Component {

  render() {

    // only render if authenticated
    if (!this.props.authenticated) {
      return null;
    }

    return (
      <div className="login">
        <h2 className="account-item" onClick={() => this.props.setLogout()}>Abmelden</h2>
        <h2 className="account-item" onClick={() => this.props.showManageAccount()}>Verwaltungsbereich</h2>
        <h2 className="account-item" onClick={() => this.props.showDialog()}>Neuer Ort hinzufügen</h2>
      </div>
    );
  }
}

export default Account
