import React from 'react';

class Message extends React.Component {

  componentDidMount() {
    // remove message after 20 seconds
    setTimeout(() => {
      this.props.closeMessage();
    }, 30000);
  }

  render() {

    if (!this.props.showMessage) {
      return null;
    }

    return (
      <div className="message-container">
        <h2 className="message">{this.props.message}</h2>
        <img className="close-icon" src="/x-white.svg" alt="Close" onClick={this.props.closeMessage} />
      </div>
    );
  }
}

export default Message
