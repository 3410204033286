import React from 'react';
import Layers from './Layers';

class Sidebar extends React.Component {

  constructor(props: Props) {

    super(props);

    this.state = {
      isLoading: "",
    };

  }

  highlightMap = (d,v) => {

    this.props.dataProviderCallback({highlight:d,visibility:v});
    this.props.dataProviderCallback({highlight:d,highlightClass:"highlight-project"});

  }

  unHighlightMap = (d,v) => {

    this.props.dataProviderCallback({
      highlight: { "type": "Feature","geometry": {"type": "point","coordinates": [0,0]} },
      highlightClass : "highlight-project"
    });

  }


  render() {

    return (
      <div className="sidebar">
        <Layers dataFromParent={this.props.dataFromParent} dataProviderCallback={this.props.dataProviderCallbackLayer} />

        {this.props.dataFromParent.projects.features && this.props.dataFromParent.projects.features.map(d =>
          <div key={d.properties.title} className="projects" onMouseOver={() => {this.highlightMap(d,"visible")} } onMouseOut={() => {this.unHighlightMap(d,"hidden")} }>
            <h2 className="sidebar-title">{d.properties.title}</h2>
            <p className="organization">{d.properties.organisation}</p>
            <p className="sidebar-item">{d.properties.description} <a href={"https://www.mein.berlin.de/" +d.properties.url} rel="noopener noreferrer" target="_blank">[link]</a></p>
            <p className="sidebar-item">Letzter Tag: {d.properties.active_phase.split("'")[3].split(" ")[0]}</p>
            <p className="sidebar-item">Noch: {d.properties.active_phase.split("'")[1]}</p>
            <div className="progress-bar">
              <span style={{width: d.properties.active_phase.split(",")[0].substring(1) + "%"}}></span>
            </div>
          </div>
        )}

      </div>
    );
  }
}

export default Sidebar
