import React from "react";
import {geoTransform,geoPath} from "d3-geo";
import {geoCircle} from 'd3-geo';
import * as d3 from "d3";
import mapboxgl from "mapbox-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
import 'mapbox-gl/dist/svg/mapboxgl-ctrl-compass.svg';
import 'mapbox-gl/dist/svg/mapboxgl-ctrl-geolocate.svg';
import 'mapbox-gl/dist/svg/mapboxgl-ctrl-zoom-in.svg';
import 'mapbox-gl/dist/svg/mapboxgl-ctrl-zoom-out.svg';


class Basemap extends React.Component {

  constructor(props: Props) {
    super(props);

    this.state = {
      map: null,
      setPoi:true,
      highlight:false,
      tooltipInfo: {
        name: 'name',
        x: 0,
        y: 0,
        visibile: "hidden"
      },
      newPoi: { "type": "Feature","geometry": {"type": "point","coordinates": [0,0]},"properties": {"poi": "new","radius": 0} }

    };

  }

  componentDidMount() {

    // TODO: change token
    mapboxgl.accessToken = "pk.eyJ1IjoibWlsbGU5MSIsImEiOiJjazBuczFiemUwMndsM2VydWJzaDh3dGNuIn0.5Ac6-Du_lEIqpDOINjeaVw";

    var bounds = [[12.916699538709182, 52.308478359854405], // Southwest coordinates
                  [13.877118099694957, 52.56579968290571] // Northeast coordinates
                ];
    var map = new mapboxgl.Map({
      container: 'map-container', // container id
      style: "mapbox://styles/mapbox/light-v10", // light default
      center: [13.385450090392179,52.41869781617749],
      zoom: 11,
      maxBounds: bounds
    });

    map.dragRotate.disable();
    map.touchZoomRotate.disableRotation();
    map.addControl(new mapboxgl.NavigationControl({showCompass: false}),'top-right');

    const canvas = map.getCanvasContainer();
    const svg = d3.select(canvas).append("svg");

    const bezirkSvg = svg.append("g").attr("id", "bezirk-svg");
    const poiSvg = svg.append("g").attr("id", "poi-svg");
    const devPlanSvg = svg.append("g").attr("id", "dev-plan-svg");
    const redevAreaSvg = svg.append("g").attr("id", "redev-area-svg");
    const projectSvg = svg.append("g").attr("id", "project-svg");

    const onD3Mouseover = this.onD3Mouseover
    const onD3Mouseout = this.onD3Mouseout
    const onD3Mouseclick = this.onD3Mouseclick

    if (this.props.dataFromParent.bezirk_boundary.features) {
      bezirkSvg.selectAll('.bezirk-boundary')
      .data(this.props.dataFromParent.bezirk_boundary.features)
      .enter().append('path')
      .style("fill", 'none' )
      .style("stroke","grey")
      .style("stroke-width","2")
      .attr("fill-opacity","0.5")
      .attr("class", "bezirk-boundary")
    }

    if (this.props.dataFromParent.dev_plans.features) {
      devPlanSvg.selectAll('.dev-plan')
      .data(this.props.dataFromParent.dev_plans.features)
      .enter().append('path')
      .style("fill", '#5C87C5' )
      .style("stroke", '#5C87C5' )
      .attr("fill-opacity","0.5")
      .attr("class", "dev-plan")
      .on("mouseover", function(d,i) {onD3Mouseover(d,i,this,"dev_plans")})
      .on("mouseout", function(d) {onD3Mouseout(d,this)})
    }


    if (this.props.dataFromParent.redev_areas.features) {
      redevAreaSvg.selectAll('.redev-area')
      .data(this.props.dataFromParent.redev_areas.features)
      .enter().append('path')
      .style("fill", '#43B7C2' )
      .style("stroke","#43B7C2")
      .attr("fill-opacity","0.5")
      .attr("class", "redev-area")
      .on("mouseover", function(d,i) {onD3Mouseover(d,i,this,"redev_areas")})
      .on("mouseout", function(d) {onD3Mouseout(d,this)})
    }

    if (this.props.dataFromParent.projects.features) {
      projectSvg.selectAll('.projects')
      .data(this.props.dataFromParent.projects.features)
      .enter().append('circle')
      .attr("r", 4)
      .style("fill", function(d) { 
        if (d.properties.district==="Treptow-Köpenick"){
          return  '#FFAD47'
        }
        else {
          return 'grey'
        }
      })
      .style("stroke",function(d) { 
        if (d.properties.district==="Treptow-Köpenick"){
          return  '#FFAD47'
        }
        else {
          return 'grey'
        }
      })
      .attr("fill-opacity","0.7")
      .attr("class", "projects")
      .on("click", function(d,i) {onD3Mouseclick(d,i,this,"poi")})
      .on("mouseover", function(d,i) {onD3Mouseover(d,i,this,"projects")})
      .on("mouseout", function(d) {onD3Mouseout(d,this)})
    }

    this.updateMap(map)

    map.on("viewreset", this.updateMap);
    map.on("move",      this.updateMap);
    map.on("moveend",   this.updateMap);
    map.on("click",     this.mapClick);
    map.on("mouseover", this.mapMouseOver);

    this.setState({map,
      poi:this.props.dataFromParent.poi,
      projects:this.props.dataFromParent.projects,
      dev_plans:this.props.dataFromParent.dev_plans,
      slidervalue:this.props.dataFromParent.slidervalue,
      poiSvg:poiSvg,
      newRadius:this.props.dataFromParent.newRadius,
      layerStatus:this.props.dataFromParent.layerStatus
    });

  }

  componentDidUpdate(prevProps){
  

    this.focusMap(this.props.dataFromParent.highlight,this.props.dataFromParent.highlightClass )
    
 
    //  console.log(this.props.dataFromParent.poiId )


    let layers = this.props.dataFromParent.layerStatus;
    for (const layer of layers) {
      d3.select("g#"+layer.id).selectAll("circle,path").attr("visibility", () => {
        return layer.checked ? "visible" : "hidden";
      });
    }
     
     //console.log(this.props.dataFromParent.currentPoi.id)
     if (!this.props.dataFromParent.showDialog ){
       this.state.poiSvg.selectAll(".tmp-poi").remove()
       this.state.poiSvg.selectAll(".tmp-radius-poi").remove()
     }


    const features = this.props.dataFromParent.poi ? this.props.dataFromParent.poi.features : [];
      if (features) {
        var radius =  this.state.poiSvg.selectAll('.radius-poi').data(features);
        const onD3Mouseover = this.onD3Mouseover
        const onD3Mouseout = this.onD3Mouseout
        const onD3Mouseclick = this.onD3Mouseclick

        radius.enter()
        .append("path")
        .merge(radius)
        .attr("fill-opacity","0.2")
        .style("fill","rgba(145, 133, 190, 1)")
        .style("stroke","rgba(145, 133, 190, 1)")
        .attr("class", "radius-poi")
        .attr("id", function(d, i) { return "id"+d.properties.id; });
       

        radius.exit().remove()

        var poi =  this.state.poiSvg.selectAll('.poi').data(features);

        poi.enter()
        .append('circle')
        .merge(poi)
        .attr("r", 5)
        .style("fill", 'rgba(145, 133, 190, 1)' )
        .attr("class", "poi")
        .on("mouseover", function(d,i) {onD3Mouseover(d,i,this,"poi")})
        .on("mouseout", function(d) {onD3Mouseout(d,this)})

        poi.exit().remove()

       //console.log(this.props.dataFromParent.currentPoi)
/*        const currentPoi = this.props.dataFromParent.currentPoi;
        if (currentPoi.id !== null){

         d3.selectAll('path#id'+currentPoi.id+'.radius-poi').style("fill","rgba(145, 133, 190, 0)").style("stroke","rgba(145, 133, 190, 0)");
          /*console.log(this.props.dataFromParent.poiId )
          */

       /*  var newPoi = { "type": "Feature","geometry": {"type": "point","coordinates": [currentPoi.lat,currentPoi.lon]},"properties": {"poi": "new","radius": 0} }

          this.state.poiSvg.selectAll('.tmp-radius-poi')
         .data([newPoi])
         .enter().append("path")
         .attr("fill-opacity","0.2")
         .style("fill","rgba(145, 133, 190, 1)")
         .style("stroke","rgba(145, 133, 190, 1)")
         .attr("class", "tmp-radius-poi")
       
    
     }*/
        

      }

      this.updateMap()


  }

  focusMap = (point,highlightClass) => {

      if (point) {

        const features = [point] || [];

       //console.log(features[0].geometry.coordinates )
        var highlight =  this.state.poiSvg.selectAll('.'+highlightClass).data(features)

        highlight.enter()
        .append('circle')
        .attr("r", 7)
        .attr("fill-opacity","0.7")
        .attr("class", highlightClass)
       // .style("visibility", "hidden" );


        highlight.exit().remove()

        d3.select("."+highlightClass).selectAll("circle").attr("visibility", () => {
              return false ? "visible" : "hidden";});

        this.updateMap()
    }

  }

  mapMouseOver = (d) => {
   //this.props.dataProviderCallback({tooltipInfo:{visible: "hidden"},sidebarInfo:this.state.sidebarInfo,newPoi:this.state.newPoi});

    //console.log(d)

  }


  mapClick = (d) => {

 
    if (this.props.dataFromParent.showDialog ){

      var newPoi = { "type": "Feature","geometry": {"type": "point","coordinates": [d.lngLat.lat,d.lngLat.lng]},"properties": {"poi": "new","radius": 0} }

      this.state.poiSvg.selectAll('.tmp-radius-poi')
     .data([newPoi])
     .enter().append("path")
     .attr("fill-opacity","0.2")
     .style("fill","rgba(145, 133, 190, 1)")
     .style("stroke","rgba(145, 133, 190, 1)")
     .attr("class", "tmp-radius-poi")

      this.state.poiSvg.selectAll('.tmp-poi')
     .data([newPoi])
     .enter().append('circle')
     .attr("r", 5)
     .style("fill", 'rgba(145, 133, 190, 1)' )
     .attr("class", "tmp-poi")

      this.props.dataProviderCallback({newCoords: [d.lngLat.lat,d.lngLat.lng],tooltipInfo:{name:"name",x:0, y:0, visible: "hidden"}});

      this.updateMap()

    }

  }

  unproject = (d) => {

    const tmpMap = this.state.map
    const t = tmpMap.unproject(d)

    return [t.lng,t.lat];
  }


  onD3Mouseover = (d,info,that,type) => {


    switch(type) {
      case "projects":
        this.setState({tooltipInfo:{type:"projects",info:info.properties,x:d.clientX-5, y:d.clientY-100, visible: "visible",open:true}});
      break;
      case "poi":
        this.setState({tooltipInfo:{type:"poi",info:info.properties.poi, x:d.clientX+12, y:d.clientY-50, visible: "visible"}});
      break;
      case "redev_areas":
        this.setState({tooltipInfo:{type:"redev_areas",info:info.properties.sanierung_id + ": " + info.properties.sanierung_name, x:d.clientX+12, y:d.clientY, visible: "visible"}});
      break;
      case "dev_plans":
        this.setState({tooltipInfo:{type:"dev_plans",info:info.properties.current_bplan_name+ ": " + info.properties.last_date, x:d.clientX+12, y:d.clientY-50, visible: "visible"}});
      break;
      default:
        console.log("No type");
    }

    d3.select(that)
    .attr("r", 7)
    .attr("stroke-width", 2)

    const tooltipInfo = this.state.tooltipInfo

    this.props.dataProviderCallback({tooltipInfo,sidebarInfo:this.state.sidebarInfo,newPoi:this.state.newPoi});

  }


  onD3Mouseout = (d,that) => {

   d3.select(that)
   .attr("r", 4)
   .attr("stroke-width", 1)

   //this.props.dataProviderCallback({tooltipInfo:{...this.state,visible: "hidden",open:false},sidebarInfo:this.state.sidebarInfo,newPoi:this.state.newPoi});

  }

  onD3Mouseclick = (d,info,that,type) => {

    //console.log(info)

    this.setState({sidebarInfo:info})

    d3.select(that)
    .attr("r", 7)
    .attr("stroke-width", 2)

  }

  updateMap = (basemap) => {


    var tmpMap;
    if (this.state.map === null) {
      tmpMap = basemap;
    } else {
      tmpMap = this.state.map;
    }

    function project(d) {

      return tmpMap.project(new mapboxgl.LngLat(+d[1], +d[0]));

    }

    function projectPoint(lon, lat) {
      var point = tmpMap.project(new mapboxgl.LngLat(lon, lat));
      this.stream.point(point.x, point.y);
    }

    var transform = geoTransform({point:projectPoint});
    var geopath = geoPath().projection(transform);
    var circle = geoCircle();

    const rad = this.props.dataFromParent.slidervalue;
   // console.log(currentPoi)
    const currentPoi = this.props.dataFromParent.currentPoi;
     //console.log(currentPoi)

    //console.log(rad)

    d3.select("#bezirk-svg  ").selectAll(".bezirk-boundary")
      .attr( "d", geoPath().projection(transform));


    d3.select("#poi-svg").selectAll(".highlight-poi")
      .attr("cx", function(d) { return project(d.geometry.coordinates).x })
      .attr("cy", function(d) { return project(d.geometry.coordinates).y })


    d3.select("#poi-svg").selectAll(".highlight-project")
      .attr("cx", function(d) { return project(d.geometry.coordinates).x })
      .attr("cy", function(d) { return project(d.geometry.coordinates).y })

    d3.select("#poi-svg").selectAll(".poi")
      .attr("cx", function(d) { return project(d.geometry.coordinates).x })
      .attr("cy", function(d) { return project(d.geometry.coordinates).y })


    d3.select("#poi-svg").selectAll(".tmp-poi")
      .attr("cx", function(d) { return project(d.geometry.coordinates).x })
      .attr("cy", function(d) { return project(d.geometry.coordinates).y })


    d3.select("#poi-svg").selectAll(".radius-poi")
      .attr("d", function(d) {

          console.log(currentPoi)
          
          if (d.properties.id === currentPoi.id){
          
            return geopath(circle.center([d.geometry.coordinates[1],d.geometry.coordinates[0]]).radius(rad)())
          }
          else{
            return  geopath(circle.center([d.geometry.coordinates[1],d.geometry.coordinates[0]]).radius(d.properties.radius)())
          }
          
      })


    d3.select("#poi-svg").selectAll(".tmp-radius-poi")
      .attr("d", function(d) {
//           console.log(d)

          return  geopath(circle.center([d.geometry.coordinates[1],d.geometry.coordinates[0]]).radius(rad)())
      })


    d3.select("#dev-plan-svg").selectAll(".dev-plan")
      .attr( "d", geoPath().projection(transform))

    d3.select("#redev-area-svg").selectAll(".redev-area")
      .attr( "d", geoPath().projection(transform));

    d3.select("#project-svg").selectAll(".projects")
      .attr("cx", function(d) { return project(d.geometry.coordinates).x })
      .attr("cy", function(d) { return project(d.geometry.coordinates).y })


   /* d3.select("#poi-svg").selectAll(".new-radius-poi")
      .attr("d", function(d) { return  geopath(circle.center([d.geometry.coordinates[1],d.geometry.coordinates[0]]).radius(rad)())});*/


 }

  render() {
    return (

      <div id="map-container"></div>

    );
  }
}

export default Basemap
