import React from 'react';
import {RangeStepInput} from 'react-range-step-input';

class RangeSlider extends React.Component {
    constructor(props) {
    super(props);
    this.state  = {value: this.props.startValue}
    this.onChange = this.onChange.bind(this);
  }

  onChange = (e) => {
    
     const value = e.target.value;
     this.props.onSlide({value});
     this.setState({ value})

  }

  render() {

    return (
      <div>
      
        <RangeStepInput 
            id={"range-slide"}
            min={0} max={100}
            value={this.state.value} step={1}
            onChange={this.onChange.bind(this)}/>
       
        </div>
    );
  }
}
export default  RangeSlider